
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import useTasks from '@/compositions/tasks/useTasks'
import { taskStatuses } from '@/definitions/tasks/data'

export default defineComponent({
  components: {
    TmDropdownItem,
  },
  emits: ['collapse'],
  setup() {
    const { openModal } = useModals()
    const { openEditBoardStage, openDeleteBoardStage } = useTasks()

    const openMoveAllTasks = () => openModal('statusSelect', {
      modalTitle: 'Move all tasks',
      description: [
        {
          text: '23 tasks',
          style: 'semi-bold',
        },
        ' will be moved to another column. Please select a new column for these tasks.',
      ],
      label: 'Select column',
      options: taskStatuses,
      btnText: 'Move 23 tasks',
    })

    return {
      openEditBoardStage,
      openDeleteBoardStage,
      openMoveAllTasks,
    }
  },
})
