
import { defineComponent } from 'vue'
import TasksMain from '@/components/pages/tasks/index/TasksMain.vue'
import TasksFilter from '@/components/pages/tasks/index/TasksFilter.vue'

export default defineComponent({
  components: {
    TasksMain,
    TasksFilter,
  },
})
