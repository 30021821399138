import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tm_dropdown_item, {
      label: "Collapse",
      icon: "close_fullscreen",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('collapse')))
    }),
    _createVNode(_component_tm_dropdown_item, {
      label: "Move all tasks",
      icon: "tmi-arrow-move",
      onClick: _ctx.openMoveAllTasks
    }, null, 8, ["onClick"]),
    _createVNode(_component_tm_dropdown_item, {
      label: "Edit stage",
      icon: "edit",
      onClick: _ctx.openEditBoardStage
    }, null, 8, ["onClick"]),
    _createVNode(_component_tm_dropdown_item, {
      label: "Manage stages",
      icon: "settings",
      "border-bottom": "",
      to: { name: 'base.tasks.settings.detailed.stages' }
    }, null, 8, ["to"]),
    _createVNode(_component_tm_dropdown_item, {
      label: "Add stage to the left",
      icon: "arrow_back"
    }),
    _createVNode(_component_tm_dropdown_item, {
      label: "Add stage to the right",
      icon: "arrow_forward",
      "border-bottom": ""
    }),
    _createVNode(_component_tm_dropdown_item, {
      label: "Delete stage",
      icon: "delete",
      onClick: _ctx.openDeleteBoardStage
    }, null, 8, ["onClick"])
  ]))
}