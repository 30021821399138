import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tasks_column = _resolveComponent("tasks-column")!
  const _component_kanban_main = _resolveComponent("kanban-main")!

  return (_openBlock(), _createBlock(_component_kanban_main, {
    columns: _ctx.columns,
    "onUpdate:columns": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.columns) = $event)),
    items: _ctx.tasks
  }, {
    column: _withCtx(({ column }) => [
      _createVNode(_component_tasks_column, _mergeProps(column, {
        onCollapse: ($event: any) => (_ctx.updateColumn({ ...column, collapsed: !column.collapsed })),
        onUpdateItemPosition: _ctx.updateItemPosition
      }), null, 16, ["onCollapse", "onUpdateItemPosition"])
    ]),
    _: 1
  }, 8, ["columns", "items"]))
}