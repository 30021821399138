
import { defineComponent, ref } from 'vue'
import { tasksColumnsMock, tasksMock } from '@/definitions/tasks/data'
import type { KanbanColumnType, KanbanItemType } from '@/definitions/shared/kanban/types'
import KanbanMain from '@/components/pages/shared/kanban/KanbanMain.vue'
import TasksColumn from '@/components/pages/tasks/TasksColumn.vue'

export default defineComponent({
  components: {
    TasksColumn,
    KanbanMain,
  },
  setup() {
    const columns = ref(tasksColumnsMock)

    const tasks = ref(tasksMock.map((e: any, i: number) => ({ ...e, index: i })))

    const updateColumn = (item: KanbanColumnType) => {
      columns.value = columns.value.map((e: KanbanColumnType) => e.id === item.id ? item : e)
    }

    const updateItemPosition = (value: KanbanItemType) => {
      tasks.value = tasks.value.map((e: KanbanItemType) => ({
        ...e,
        column: value.id === e.id ? value.column : e.column,
        index: e.id === value.id ? value.index : e.index < value.index ? e.index - 1 : e.index + 1,
      }))
    }

    return {
      columns,
      tasks,
      updateColumn,
      updateItemPosition,
    }
  },
})
