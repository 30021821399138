import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tasks_column_actions = _resolveComponent("tasks-column-actions")!
  const _component_tasks_card = _resolveComponent("tasks-card")!
  const _component_kanban_column = _resolveComponent("kanban-column")!

  return (_openBlock(), _createBlock(_component_kanban_column, {
    onCollapse: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('collapse')))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_tasks_column_actions, {
        onCollapse: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('collapse')))
      })
    ]),
    card: _withCtx(({ item }) => [
      (_openBlock(), _createBlock(_component_tasks_card, _mergeProps(item, {
        key: item.id
      }), null, 16))
    ]),
    _: 1
  }))
}