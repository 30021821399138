import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column h100pr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tasks_filter = _resolveComponent("tasks-filter")!
  const _component_tasks_main = _resolveComponent("tasks-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tasks_filter, { class: "mb-4" }),
    _createVNode(_component_tasks_main)
  ]))
}