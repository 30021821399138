
import { defineComponent, ref } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdownDueDateBase from '@/components/shared/dropdowns/TmDropdownDueDateBase.vue'
import TmButton from '@/components/shared/TmButton.vue'
import KanbanCard from '@/components/pages/shared/kanban/KanbanCard.vue'

import { assigneeOptions } from '@/definitions/_general/_data/optionsList'
import type { PersonType, PriorityType } from '@/definitions/shared/types'
import type { PropType } from 'vue'
import type { TaskCardChecklistType } from '@/definitions/tasks/types'
import { priorityVariant } from '@/definitions/_general/_data/priorityVariant'
import { useModals } from '@/compositions/modals'
import type { DeadlineType } from '@/definitions/_general/_types/types'

export default defineComponent({
  components: {
    TmDropdownDueDateBase,
    TmPriority,
    TmButton,
    TmAvatar,
    TmDropdown,
    TmDropdownItem,
    KanbanCard,
  },
  props: {
    title: {
      type: String,
    },
    assignee: {
      type: Object as PropType<PersonType>,
    },
    priority: {
      type: String as PropType<PriorityType>,
    },
    attachments: {
      type: Number,
    },
    checklist: {
      type: Object as PropType<TaskCardChecklistType>,
    },
    deadline: {
      type: Object as PropType<DeadlineType>,
    },
  },
  setup() {
    const { openModal } = useModals()
    const dropdownVisible = ref(false)

    return {
      dropdownVisible,
      openModal,
      assigneeOptions,
      priorityVariant,
    }
  },
})
