
import { defineComponent } from 'vue'
import TasksCard from '@/components/pages/tasks/TasksCard.vue'
import TasksColumnActions from '@/components/pages/tasks/TasksColumnActions.vue'
import KanbanColumn from '@/components/pages/shared/kanban/KanbanColumn.vue'

export default defineComponent({
  components: {
    TasksCard,
    TasksColumnActions,
    KanbanColumn,
  },
  emits: ['collapse'],
})
